/*
 * Image replacement
 */
/*
* Clearfix: contain floats
*
* For modern browsers
* 1. The space content is one way to avoid an Opera bug when the
*    `contenteditable` attribute is included anywhere else in the document.
*    Otherwise it causes space to appear at the top and bottom of elements
*    that receive the `cf` class.
* 2. The use of `table` rather than `block` is only necessary if using
*    `:before` to contain the top-margins of child elements.
*/
main section.module-header .section-wrapper .mobile-hero {
  background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-m-m.3b55800b.jpg");
  background-size: 100% auto;
}
html[data-webp=true] main section.module-header .section-wrapper .mobile-hero {
  background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-m-m.149a5f90.webp");
}
@media print, (min-resolution: 192dpi) {
  main section.module-header .section-wrapper .mobile-hero {
    background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-m-m-2x.49fd6035.jpg");
  }
  html[data-webp=true] main section.module-header .section-wrapper .mobile-hero {
    background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-m-m-2x.eb37c9ee.webp");
  }
}

@media only screen and (min-width: 431px) {
  main section.module-header .section-wrapper .mobile-hero {
    background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-dt-350h.d946e126.jpg");
    background-size: cover;
    background-position: right top;
  }
  html[data-webp=true] main section.module-header .section-wrapper .mobile-hero {
    background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-dt-350h.1dcea52e.webp");
  }
}
@media only screen and (min-width: 431px) and (min-resolution: 192dpi) {
  main section.module-header .section-wrapper .mobile-hero {
    background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-dt-350h-2x.6c29179d.jpg");
  }
  html[data-webp=true] main section.module-header .section-wrapper .mobile-hero {
    background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-dt-350h-2x.90943492.webp");
  }
}
@media only screen and (min-width: 768px) {
  main section.module-header .section-wrapper .mobile-hero {
    background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-dt-350h.d946e126.jpg");
    background-size: cover;
    background-position: right 25%;
  }
  html[data-webp=true] main section.module-header .section-wrapper .mobile-hero {
    background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-dt-350h.1dcea52e.webp");
  }
}
@media only screen and (min-width: 768px) and (min-resolution: 192dpi) {
  main section.module-header .section-wrapper .mobile-hero {
    background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-dt-350h-2x.6c29179d.jpg");
  }
  html[data-webp=true] main section.module-header .section-wrapper .mobile-hero {
    background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-dt-350h-2x.90943492.webp");
  }
}
@media only screen and (min-width: 1170px) {
  main section.module-header {
    background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-d.4b46a6f2.jpg");
    background-size: cover;
    background-position: center 25%;
    min-height: 650px;
  }
  html[data-webp=true] main section.module-header {
    background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-d.d9314262.webp");
  }
}
@media only screen and (min-width: 1170px) and (min-resolution: 192dpi) {
  main section.module-header {
    background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-d-2x.13fa2a75.jpg");
  }
  html[data-webp=true] main section.module-header {
    background-image: url("/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-d-2x.4aaa6529.webp");
  }
}
@media screen and (max-width: 1169px) {
  main section.module-header .section-wrapper .section-inner .cta {
    text-align: center;
  }
}
main section.module-header .photo-caption {
  font-size: 16px;
}
main section.module-2 {
  background-color: #C2E6E4;
}
main section.module-2 .section-wrapper h4 {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  margin: 12px auto 32px;
}
main section.module-2 .section-wrapper .three-box-callout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
main section.module-2 .section-wrapper .three-box-callout-container .three-box-callout {
  background-color: #ffffff;
  padding: 32px 24px;
  margin: 16px 11px;
}
main section.module-2 .section-wrapper .three-box-callout-container .three-box-callout__title {
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #435770;
}
main section.module-2 .section-wrapper .three-box-callout-container .three-box-callout__percent {
  font-family: "canela-deck", serif;
  font-weight: 400;
  font-size: 65px;
  font-weight: 400;
  line-height: 52px;
  text-align: center;
  color: #2C5E7B;
}
main section.module-2 .section-wrapper .three-box-callout-container .three-box-callout__copy {
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #2C5E7B;
}
@media screen and (max-width: 767px) {
  main section.module-2 .section-wrapper .callout-content p:first-of-type {
    text-align: center;
  }
}
main section.module-2 .section-wrapper h3 {
  margin: 3rem 0 2rem 0;
  text-align: center;
}
main section.module-2 .section-wrapper ul.bullets {
  list-style-type: none;
}
main section.module-2 .section-wrapper ul.bullets.bullets {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1170px) {
  main section.module-2 .section-wrapper ul.bullets.bullets {
    flex-direction: row;
    justify-content: space-between;
  }
}
main section.module-2 .section-wrapper ul.bullets.bullets li {
  padding-left: 60px;
  text-align: left;
}
main section.module-2 .section-wrapper ul.bullets.bullets li:before {
  content: " ";
  background: transparent url("/assets/img/icon-checkmark.fd77fb34.svg") 0 0 no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: 35px;
  height: 26px;
  left: 10px;
  display: block;
  color: inherit;
}
main section.section-interconnect {
  background-color: #E0EFEE;
  text-align: center;
}
main section.section-interconnect .section-wrapper {
  padding: 0;
  margin: 0;
  max-width: unset;
}
@media screen and (max-width: 767px) {
  main section.section-interconnect .section-wrapper {
    display: flex;
    flex-direction: column;
  }
}
main section.section-interconnect .section-wrapper .section-content {
  padding: 64px 15px;
}
@media screen and (max-width: 767px) {
  main section.section-interconnect .section-wrapper .section-content {
    text-align: center;
  }
}
main section.section-interconnect .section-wrapper .section-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 400px;
  position: relative;
}
main section.section-interconnect .section-wrapper .section-image.evo-lazy-loaded {
  background-image: url("/assets/img/content/callout-rosalie-group-baking-m-lg.6cf4b2dd.jpg");
}
html[data-webp=true] main section.section-interconnect .section-wrapper .section-image.evo-lazy-loaded {
  background-image: url("/assets/img/content/callout-rosalie-group-baking-m-lg.87293768.webp");
}
main section.section-interconnect .section-wrapper .section-image .photo-caption {
  position: absolute;
  display: block;
}
main section.section-interconnect .section-wrapper .section-image .photo-caption.actual-patient {
  background: transparent;
  width: unset;
  right: 12px;
  bottom: 12px;
  text-shadow: 2px 2px 8px #000000;
}
main section.section-interconnect .section-wrapper .section-image .photo-caption.actual-patient span.footnote {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.07px;
}
main section.section-interconnect .section-wrapper .section-image .photo-caption.actual-patient span.footnote::before {
  background-color: transparent;
}
main section.section-interconnect .section-wrapper .interconnect-logo {
  margin: 0 0 2rem 0;
  display: block;
  width: 100%;
  height: 31px;
  background-size: 174px;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: left;
  text-indent: -9999px;
}
main section.section-interconnect .section-wrapper .interconnect-logo.evo-lazy-loaded {
  background-image: url("/assets/img/logo-interconnect.69d2ad15.svg");
}
main section.section-interconnect .section-wrapper .footnotes {
  margin-top: 1rem;
}
main section.section-program {
  background-color: #E0EFEE;
}
main section.section-program .section-wrapper {
  padding: 0;
  margin: 0;
  max-width: unset;
}
@media screen and (max-width: 767px) {
  main section.section-program .section-wrapper {
    display: flex;
    flex-direction: column;
  }
}
main section.section-program .section-wrapper .section-content {
  padding: 64px 15px;
}
@media screen and (max-width: 767px) {
  main section.section-program .section-wrapper .section-content {
    text-align: center;
  }
}
main section.section-program .section-wrapper .section-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 400px;
  position: relative;
}
main section.section-program .section-wrapper .section-image.evo-lazy-loaded {
  background-image: url("/assets/img/content/callout-rosalie-smile-m-lg.91bfd8f8.jpg");
}
html[data-webp=true] main section.section-program .section-wrapper .section-image.evo-lazy-loaded {
  background-image: url("/assets/img/content/callout-rosalie-smile-m-lg.128eac36.webp");
}
main section.section-program .section-wrapper .section-image .photo-caption {
  position: absolute;
  display: block;
}
main section.section-program .section-wrapper .section-image .photo-caption.actual-patient {
  background: transparent;
  width: unset;
  right: 12px;
  bottom: 12px;
  text-shadow: 2px 2px 8px #000000;
}
main section.section-program .section-wrapper .section-image .photo-caption.actual-patient span.footnote {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.07px;
}
main section.section-program .section-wrapper .section-image .photo-caption.actual-patient span.footnote::before {
  background-color: transparent;
}
main section.section-program .section-wrapper .icon-phone-teal {
  position: relative;
  margin: 2rem auto 1rem auto;
  padding: 0 10px 0 calc(49px + 1rem);
}
main section.section-program .section-wrapper .icon-phone-teal:before {
  content: "";
  position: absolute;
  left: 0;
  top: -14px;
  display: block;
  width: 49px;
  height: 60px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
main section.section-program .section-wrapper .icon-phone-teal.evo-lazy-loaded:before {
  background-image: url("/assets/img/icon-phone-teal.8b822758.svg");
}
main section.section-program .section-wrapper .icon-phone-teal a {
  text-decoration: none;
}
main section.module-ddg .section-wrapper {
  padding: 6rem 1rem 4rem;
}

/*! preload tablet webp 1x="/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-m-t.3b55800b.jpg" 2x="/assets/img/heros/hero-leslie-rosalie-smiling-looking-left-m-t-2x.49fd6035.jpg" */
@media only screen and (min-width: 768px) {
  main section.section-interconnect .section-wrapper {
    text-align: left;
    width: unset;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-content: center;
  }
  main section.section-interconnect .section-wrapper .section-content {
    padding: 64px 15px;
  }
  main section.section-interconnect .section-wrapper .section-content p {
    font-size: 20px;
    line-height: 1.5;
  }
  main section.section-interconnect .section-wrapper .section-image .photo-caption.actual-patient {
    right: 20px;
    bottom: 20px;
  }
  main section.section-interconnect .section-wrapper .section-image.evo-lazy-loaded {
    background-image: url("/assets/img/content/callout-rosalie-group-baking-d-lg.e8b2dda7.jpg");
  }
  html[data-webp=true] main section.section-interconnect .section-wrapper .section-image.evo-lazy-loaded {
    background-image: url("/assets/img/content/callout-rosalie-group-baking-d-lg.d733f1be.webp");
  }
  main section.section-interconnect .section-wrapper .interconnect-logo {
    height: 61px;
    background-size: 342px;
    background-position: left center;
  }
  main section.section-program .section-wrapper {
    width: unset;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-content: center;
  }
  main section.section-program .section-wrapper .section-content {
    padding: 64px 15px;
    grid-column: 1;
    grid-row: 1;
  }
  main section.section-program .section-wrapper .section-content p {
    font-size: 20px;
    line-height: 1.5;
  }
  main section.section-program .section-wrapper .section-image {
    grid-column: 2;
    grid-row: 1;
  }
  main section.section-program .section-wrapper .section-image .photo-caption.actual-patient {
    right: 20px;
    bottom: 20px;
  }
  main section.section-program .section-wrapper .section-image .photo-caption.actual-patient {
    right: unset;
    left: 20px;
    bottom: 20px;
  }
  main section.section-program .section-wrapper .section-image.evo-lazy-loaded {
    background-image: url("/assets/img/content/callout-rosalie-smile-d-lg.c36f2fb7.jpg");
  }
  html[data-webp=true] main section.section-program .section-wrapper .section-image.evo-lazy-loaded {
    background-image: url("/assets/img/content/callout-rosalie-smile-d-lg.915d344c.webp");
  }
}
@media only screen and (min-width: 1170px) {
  main section.module-2 .section-wrapper {
    width: 1170px;
  }
  main section.module-2 .section-wrapper h4 {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    margin: 12px auto 32px;
  }
  main section.module-2 .section-wrapper .three-box-callout-container {
    flex-direction: row;
    justify-content: space-between;
  }
  main section.module-2 .section-wrapper .three-box-callout-container .three-box-callout {
    width: 33%;
  }
  main section.module-2 .section-wrapper ul.bullets li {
    width: 33%;
    margin: 0;
  }
  main section.section-interconnect .section-wrapper .section-content {
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 32px;
    padding-right: 0px;
  }
  main section.section-interconnect .section-wrapper .section-content .content {
    width: 553px;
    margin: 0 auto 0 0;
    padding-right: 16px;
  }
  main section.section-interconnect .section-wrapper .section-image {
    min-height: 678px;
  }
  main section.section-interconnect .section-wrapper .interconnect-logo {
    background-position: center left;
  }
  main section.section-interconnect .section-wrapper h2 {
    letter-spacing: -1.2px;
  }
  main section.section-program .section-wrapper .section-content {
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 0px;
    padding-right: 32px;
  }
  main section.section-program .section-wrapper .section-content .content {
    width: 553px;
    margin: 0 0 0 auto;
    padding-left: 16px;
  }
  main section.section-program .section-wrapper .section-image {
    min-height: 554px;
  }
  main section.section-program .section-wrapper .icon-phone-teal {
    font-size: 24px;
    padding: 0 10px 0 calc(52px + 1rem);
    margin-left: 0;
  }
  main section.section-program .section-wrapper .icon-phone-teal:before {
    width: 52px;
    height: 62px;
  }
}